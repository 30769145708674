import "./Model.css";
import CardList from "./CardList";

const Homepage = () => {
  return (
    <div className="homepage">
      <CardList />
      <div className="spacer"></div>
      <div className="foot">© 2022 Hamzey Beauferris. All Rights Reserved.</div>
    </div>
  );
};

export default Homepage;
