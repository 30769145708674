/* eslint-disable react/no-unknown-property */
import "./Closet.css";
import Model from "./Model";
import { Suspense, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { useState } from "react";
// import { OrbitControls } from "@react-three/drei";
import "./Closet.css";

const Closet = () => {
  //closet state
  const [hatState, setHatState] = useState(
    JSON.parse(localStorage.getItem("hat")) || {
      isOpen: false,
      current: "red-hat",
    }
  );
  const [topState, setTopState] = useState(
    JSON.parse(localStorage.getItem("top")) || {
      isOpen: false,
      current: "jacket",
    }
  );
  const [bottomState, setBottomState] = useState(
    JSON.parse(localStorage.getItem("bottom")) || {
      isOpen: false,
      current: "pants",
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [position, setPosition] = useState([0, -5, 0]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [closet, setCloset] = useState(false);

  useEffect(() => {
    localStorage.setItem("hat", JSON.stringify(hatState));
    localStorage.setItem("top", JSON.stringify(topState));
    localStorage.setItem("bottom", JSON.stringify(bottomState));
  }, [hatState, topState, bottomState]);

  const reposition = () => {
    // setPosition([0, -5, 0]);
    setBottomState({ ...bottomState, isOpen: false });
    setTopState({ ...topState, isOpen: false });
    setHatState({ ...hatState, isOpen: false });
    setIsZoomed(false);
  };

  const zoomHead = () => {
    // setPosition([0.5, -6.5, 2]);

    setHatState({ ...hatState, isOpen: !hatState.isOpen });

    setBottomState({ ...bottomState, isOpen: false });
    setTopState({ ...topState, isOpen: false });
    setIsZoomed(true);
  };

  const zoomTop = () => {
    // setPosition([0.5, -5, 2.5]);
    setTopState({ ...topState, isOpen: !topState.isOpen });
    setBottomState({ ...bottomState, isOpen: false });
    setHatState({ ...hatState, isOpen: false });
    setIsZoomed(true);
  };

  const zoomBottom = () => {
    // setPosition([0.5, -4, 2.5]);
    setBottomState({ ...bottomState, isOpen: !bottomState.isOpen });
    setHatState({ ...hatState, isOpen: false });
    setTopState({ ...topState, isOpen: false });
    setIsZoomed(true);
  };

  const changeHat = (event) => {
    setHatState({ ...hatState, current: event.currentTarget.value });
    localStorage.setItem("hat", JSON.stringify(hatState));
  };

  const changeTop = (event) => {
    setTopState({ ...topState, current: event.currentTarget.value });
  };

  const changeBottom = (event) => {
    setBottomState({ ...bottomState, current: event.currentTarget.value });
  };

  const toggleCloset = () => {
    setCloset(!closet);
    isZoomed ? reposition() : zoomHead();
    setHatState({ ...hatState, isOpen: true });
  };

  return (
    <>
      <Canvas>
        <ambientLight intensity={0.4} />
        <directionalLight />
        {/* <OrbitControls enableZoom={false} /> */}
        <Suspense fallback={null}>
          <Model
            hatState={hatState}
            topState={topState}
            bottomState={bottomState}
            scale={1.1}
            position={position}
          />
        </Suspense>
      </Canvas>
      <div
        style={{
          width: "100vw",

          marginTop: "-83px",
        }}
      >
        <h2
          style={{
            marginLeft: "20px",
          }}
        >
          3d Avatar
        </h2>
        <a
          style={{
            marginTop: "-65px",
          }}
          className="closet-toggle-button"
          onClick={toggleCloset}
        >
          {closet ? (
            <span>Close Closet &#8593;</span>
          ) : (
            <span>Open Closet &#8595;</span>
          )}
        </a>
      </div>
      <div className={closet === false ? "closet-hide" : "closet"}>
        {/* <div>
          <button className="cancel" onClick={toggleCloset}>
            x
          </button>
        </div> */}
        <div className="closet-buttons">
          <button
            className={hatState.isOpen ? "selected" : ""}
            onClick={zoomHead}
          >
            {" "}
            Head{" "}
          </button>
          <button
            className={topState.isOpen ? "selected" : ""}
            onClick={zoomTop}
          >
            Top
          </button>
          <button
            className={bottomState.isOpen ? "selected" : ""}
            onClick={zoomBottom}
          >
            Bottom{" "}
          </button>
        </div>
        <div>
          <div className={hatState.isOpen ? "closet-choice" : "hide"}>
            <button
              className={hatState.current === "black-hat" ? "highlight" : ""}
              value="black-hat"
              onClick={changeHat}
            >
              <img src="./images/closet/black-hat.png" alt="black hat" />
            </button>
            <button
              className={hatState.current === "red-hat" ? "highlight" : ""}
              value="red-hat"
              onClick={changeHat}
            >
              <img src="./images/closet/red-hat.png" alt="red hat" />
            </button>
          </div>

          <div className={topState.isOpen ? "closet-choice" : "hide"}>
            <button
              className={topState.current === "jacket" ? "highlight" : ""}
              value="jacket"
              onClick={changeTop}
            >
              <img src="./images/closet/jacket.png" alt="jacket" />
            </button>
            <button
              className={topState.current === "shirt" ? "highlight" : ""}
              value="shirt"
              onClick={changeTop}
            >
              <img src="./images/closet/shirt.png" alt="shirt" />
            </button>
          </div>
          <div className={bottomState.isOpen ? "closet-choice" : "hide"}>
            <button
              className={bottomState.current === "pants" ? "highlight" : ""}
              value="pants"
              onClick={changeBottom}
            >
              <img src="./images/closet/pants.png" alt="pants" />
            </button>
            <button
              className={bottomState.current === "shorts" ? "highlight" : ""}
              value="shorts"
              onClick={changeBottom}
            >
              <img src="./images/closet/shorts.png" alt="shorts" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Closet;
