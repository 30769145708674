import Card from "./Card";
import Closet from "./Closet";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import "./Card.scss";
import { gsap } from "gsap/index";
import Flip from "gsap/Flip";
import CustomEase from "gsap/CustomEase";

let data = [
  {
    id: 0,
    type: "3d",
    title: "Dress Me",
    stack: ["React", "Three.js", "Blender"],

    p2: "",
    p3: "",
    img1: "./images/closet/model.png",
    img2: "./images/closet/model1.png",
    img3: "./images/closet/model2.png",
    img4: "./images/closet/model3.png",
  },
];

const CardList = () => {
  //Card states
  const [toggle, setToggle] = useState(false);
  const [layoutState, setLayoutState] = useState();
  const [current, setCurrent] = useState(-1);

  gsap.registerPlugin(Flip);
  gsap.registerPlugin(CustomEase);

  //REF TO THE DOM ELEMENT WE WANT TO GROW
  const boxRef = useRef();
  // const details = useRef();

  //Gets all the children of our element
  const q = gsap.utils.selector(boxRef);

  CustomEase.create("apple", ".62,.61,.45,1");

  useEffect(() => {
    toggle === true
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "");
  }, [toggle]);

  //update layout whenever our layout state changes
  useLayoutEffect(() => {
    if (!layoutState) return;

    const flip = Flip.from(layoutState, {
      duration: 0.5,

      ease: "apple",
      absolute: true,

      toggleClass: "activeCard",
      targets: q(".card"),
      delay: 0.05,
    });

    return () => {
      flip.kill();
    };
  }, [layoutState, q]);

  const expand = (id) => {
    setCurrent(current === -1 ? id : -1);
    setToggle(!toggle);
    setLayoutState(Flip.getState(q(".card")));
  };

  const cardData = data.map((data) => {
    return (
      <Card
        id={data.id}
        className={data.id === current ? "card open" : "card"}
        data-flip-id={data.id}
        key={data.id}
        stack={data.stack}
        title={data.title}
        buttonTitle={data.buttonTitle}
        url={data.url}
        src={data.src}
        type={data.type}
        img={data.src}
        p1={data.p1}
        p2={data.p2}
        p3={data.p3}
        img1={data.img1}
        img2={data.img2}
        img3={data.img3}
        img4={data.img4}
        onClick={expand}
        model={data.id === 0 ? <Closet /> : ""}
      ></Card>
    );
  });

  return (
    <div ref={boxRef} className="card-list">
      <div className="intro">
        <h2>
          Hamzey Beauferris: A Frontend Developer, having fun building digital
          products.
        </h2>
        <br></br>

        <a target="blank" href="https://www.linkedin.com/in/beauferris/">
          Linkedin
        </a>
        <a target="blank" href="https://github.com/beauferris">
          Github
        </a>
        <a target="blank" href="mailto:h-beauferris@hotmail.com">
          Contact
        </a>
      </div>
      {cardData}
    </div>
  );
};

export default CardList;
